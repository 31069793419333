export default {
  presets: {
    light: {
      backgroundPrimary: '#F4F6F8',
      backgroundSecondary: '#FFFFFF',
      backgroundCardPrimary: '#F7F9F9',
      backgroundCardSecondary: '#ECFDE6',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
      color: '#000000',
      borderColor: '#bbb',
      backgroundInput: '#FFF',
      backgroundSubMenu: '#FFF',
      backgroundTagDeposit: '#6d78a7',
      backgroundTagWithdraw: '#1c4c7b',
    },
    dark: {
      backgroundSecondary: '#1f262f',
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#0f172a',
      color: '#FFFFFF',
      borderColor: '#3d4c58',
      backgroundInput: '#FFF',
      backgroundSubMenu: '#252f3b',
      backgroundTagDeposit: '#6d78a7',
      backgroundTagWithdraw: '#1c4c7b',
    },
  },
}
