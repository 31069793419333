<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useColors} from 'vuestic-ui'
import navigationRoutes from './NavigationRoutes'
import {useAuthStore} from '../../stores/auth'

const { t } = useI18n()
const sidebarWidth = computed(() => '220px')
const { getColor } = useColors()
const color = computed(() => getColor('background-secondary'))
const userType = ref()
const userRole = ref()
const storeAuth = useAuthStore()


const props = defineProps<{
  sideMenuSmall?: Boolean
}>()

function checkType(type: any) {
  if(type) {
    return type.some((i: any) => i == userType.value)
  }
}

function checkRole(role: any) {
  if(role) {
    return role.some((i: any) => i == userRole.value)
  }
}

onMounted(async () => {
  await storeAuth.getUserReq()
  userType.value = localStorage.getItem('userType')
  userRole.value = localStorage.getItem('userRole')
})
</script>

<template>
  <div class="w-full flex-wrap" v-if="userType" :width="sidebarWidth" :color="color" minimized-width="0">
    <div class="relative w-full menuItem" v-for="(route, index) in navigationRoutes.routes" :key="index">
      <router-link
        :to="route.children ? { name : route.children[0].name } : { name : route.name }"
        v-if="checkType(route.meta.userType) && checkRole(route.meta.userRole)"
        class="py-5 pr-2 pl-4 border-b border-backgroundPrimary flex h-[61px]"
      >
        <VaIcon
          :name="route.meta.icon"
          size="20px"
        />

        <div 
          v-if="sideMenuSmall" 
          class="sideMenuSmall items-center h-[60px] leading-5 font-semibold absolute left-[52px] top-[0] bg-backgroundSecondary whitespace-nowrap p-2 rounded-r-md shadow-xl hidden"
        >
          {{ t(route.displayName) }}
        </div>

        <div 
          v-else 
          class="flex justify-between items-center leading-5 font-semibold ml-3 whitespace-nowrap text-color"
        >
          {{ t(route.displayName) }}
        </div>
        
      </router-link>
      
      <div v-if="route.children" class="w-full flex flex-col absolute right-[-230px] top-0 z-10 subMenuItem shadow-xl rounded-r-sm shadow-gray-500/10">
        <template  v-for="(childRoute, index2) in route.children" :key="index2">
          <router-link
            v-if="checkType(childRoute.meta.userType) && checkRole(childRoute.meta.userRole)"
            class="w-full px-5 py-3 bg-backgroundSecondary hover:bg-backgroundPrimary last:border-0 border-b border-backgroundPrimary rounded-r-sm"
            :to="{ name : childRoute.name }"
          >
            <span class="font-bold">{{ t(childRoute.displayName) }}</span>
          </router-link>
        </template>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
  .router-link-active {
    background-color: var(--va-background-primary)
  }
  .subMenuItem {
    width: 230px;
    display: none;
    font-size: 13px;
  }
  .menuItem {
    &:hover {
      .subMenuItem {
        display: flex;
      }
      .sideMenuSmall {
        display: flex;
      }
    }
  }
</style>
