import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import axios from 'axios'
import moment from 'moment'
import PrimeVue from 'primevue/config';
import Lara from './presets/lara';
import JsonExcel from "vue-json-excel3";

axios.defaults.baseURL = import.meta.env.VITE_API_URL

// Request interceptor
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('authUser')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401 && error.config.url != '/verify-2fa-login') {
        localStorage.removeItem('is2faEnabled')
        localStorage.removeItem('is2faVerified')
        localStorage.removeItem('authUser')
        localStorage.removeItem('userRole')
        localStorage.removeItem('userType')
        router.push('/login')
        location.reload()
      } else if (error.response.status === 503) {
        return Promise.reject('Service unavailable')
      } else {
        console.log('An error occurred. Please try again later.')
      }
    }
    return Promise.reject(error)
  },
);

const app = createApp(App)

app.component("downloadExcel", JsonExcel);

app.provide('$axios', axios);
app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))
app.config.globalProperties.$moment = moment
app.use(PrimeVue, {
  unstyled: true,
  pt: Lara
});

app.mount('#app')
