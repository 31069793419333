import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../stores/auth'


const routes: Array<RouteRecordRaw> = [
  {
    name: 'Home',
    path: '/',
    component: () => import('../pages/player-transactions/PlayerTransactionsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    name: 'PlayerTransactions',
    path: '/player-transactions',
    component: () => import('../pages/player-transactions/PlayerTransactionsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    name: 'Users',
    path: '/users',
    component: () => import('../pages/users/UsersPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    name: 'UserCreateEdit',
    path: '/user/:type/:id',
    component: () => import('../pages/users/UserCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    name: 'Companies',
    path: '/companies',
    component: () => import('../pages/companies/CompaniesPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company'] },
  },
  {
    name: 'CompanyCreateEdit',
    path: '/company/:type/:id',
    component: () => import('../pages/companies/CompanyCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company'] },
  },
  {
    name: 'Clients',
    path: '/clients',
    component: () => import('../pages/clients/ClientsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'client'] },
  },
  {
    name: 'ClientCreateEdit',
    path: '/client/:type/:id',
    component: () => import('../pages/clients/ClientCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'client'] },
  },
  {
    name: 'ClientGroups',
    path: '/client-groups',
    component: () => import('../pages/client-groups/ClientGroupsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'client'] },
  },
  {
    name: 'ClientGroupCreateEdit',
    path: '/client-group/:type/:id',
    component: () => import('../pages/client-groups/ClientGroupCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'client'] },
  },
  {
    name: 'Banks',
    path: '/banks',
    component: () => import('../pages/banks/BanksPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'BankCreateEdit',
    path: '/bank/:type/:id',
    component: () => import('../pages/banks/BankCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'Vendors',
    path: '/vendors',
    component: () => import('../pages/vendors/VendorsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'VendorCreateEdit',
    path: '/vendor/:type/:id',
    component: () => import('../pages/vendors/VendorCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'CallbackLogs',
    path: '/callback-logs',
    component: () => import('../pages/callback-logs/CallbackLogsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'client'] },
  },
  {
    name: 'BankAccounts',
    path: '/bank-accounts',
    component: () => import('../pages/bank-accounts/BankAccountPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company'] },
  },
  {
    name: 'BankAccountCreateEdit',
    path: '/bank-account/:type/:id',
    component: () => import('../pages/bank-accounts/BankAccountCreateEdit.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company'] },
  },
  {
    name: 'ClientTransactions',
    path: '/client-transactions',
    component: () => import('../pages/client-transactions/ClientTransactionsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'client'] },
  },
  {
    name: 'CompanyTransactions',
    path: '/company-transactions',
    component: () => import('../pages/company-transactions/CompanyTransactionsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company'] },
  },
  {
    name: 'NewManualDeposit',
    path: '/new-manual-deposit',
      component: () => import('../pages/new-manual-deposit/NewManualDepositPage.vue'),
      meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'NewManualWithdraw',
    path: '/new-manual-withdraw',
    component: () => import('../pages/new-manual-withdraw/NewManualWithdrawPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'ClientReports',
    path: '/client-reports',
    component: () => import('../pages/client-reports/ClientReportsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'CompanyReports',
    path: '/company-reports',
    component: () => import('../pages/company-reports/CompanyReportsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'Reports',
    path: '/reports',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'CompanyLimitReport',
    path: '/company-limit-report',
    component: () => import('../pages/admin/404.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'DepositWithdrawalReport',
    path: '/deposit-withdrawal-report',
    component: () => import('../pages/admin/404.vue'),
    meta: { onlyAuthUser: true, userType: ['admin'] },
  },
  {
    name: 'Account',
    path: '/account',
    component: () => import('../pages/account/Account.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('../pages/settings/Settings.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('../pages/admin/Login.vue'),
    meta: { onlyAuthUser: false },
  },
  {
    name: 'Logout',
    path: '/logout',
    component: () => import('../pages/admin/Logout.vue'),
    meta: { onlyAuthUser: true },
  },
  {
    name: 'Verify2FA',
    path: '/verify-2fa',
    component: () => import('../pages/admin/Verify2fa.vue'),
    meta: { onlyAuthUser: true, userType: ['admin', 'company', 'client'] },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../pages/admin/404.vue'),
    meta: { onlyAuthUser: true },
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to) => {

  let isAuthenticated = localStorage.getItem('authUser')
  let is2faVerified = localStorage.getItem('is2faVerified')
  let is2faEnabled = localStorage.getItem('is2faEnabled')
  if (isAuthenticated) {
    const storeAuth = useAuthStore()
    storeAuth.getUserReq()
  }

  // let userType = localStorage.getItem('userType')
  // if(to.meta.userType) {
  //   const found = to.meta.userType.some((i:any) => i == userType)
  //   if(!found) {
  //     return {
  //       path: '/'
  //     }
  //   }
  // }

  if (to.meta.onlyAuthUser) {

    if (isAuthenticated == null) {
      return {
        path: '/login'
      }
    }

    if (to.name == 'Verify2FA') {
      if (is2faEnabled == 'true' && is2faVerified == 'true') {
        return {
          path: '/'
        }
      }
    } else {
      if (is2faEnabled == 'true' && is2faVerified == 'false') {
        return {
          path: '/verify-2fa'
        }
      }
    }

  } else if (!to.meta.onlyAuthUser && isAuthenticated != null) {
    return {
      path: '/'
    }
  }

})

export default router
