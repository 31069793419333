import {computed, ref} from 'vue';
import {defineStore} from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', () => {

  const authUserInfo = ref();
  const getAuthenticatedUser = computed(() => authUserInfo.value);
  const isAuthenticated = () => {
    authUserInfo.value = localStorage.getItem('authUser');
  };

  const user = ref();
  const getUser = computed(() => user.value);
  const getUserReq = async () => {
    await axios.get(`/user`)
    .then(response => {
      user.value = response.data.user
      localStorage.setItem('userRole', user.value.role.name.toLowerCase())

      //define a constant class fetch 1,2,3 values from there
      if(user.value.user_type_info.value == 1) {
        user.value.user_type_info.name = 'admin'
      } else if (user.value.user_type_info.value == 2) {
        user.value.user_type_info.name = 'client'
      } else if (user.value.user_type_info.value == 3) {
        user.value.user_type_info.name = 'company'
      } else {
        return
      }
      localStorage.setItem('userType', user.value.user_type_info.name.toLowerCase())
    })
    .catch(error => {
      return Promise.reject(error.response.data.error)
    })
  };

  const userDetail = ref();
  const getUserDetail = computed(() => userDetail.value);
  const getUserDetailReq = async (id: any) => {
    await axios.get(`/users/${id}`)
    .then(response => {
      userDetail.value = response.data.data
    })
    .catch(error => {
      return Promise.reject(error.response.data.error)
    })
  };

  const enable2faReq = async () => {
    try {
      return await axios.post('/enable-2fa');
    }
    catch (error) {
      return error
    }
  }

  const disable2faReq = async (payload: object) => {
    try {
      return await axios.put(`/disable-2fa`, payload);
    }
    catch (error) {
      return error
    }
  }

  const verify2faReq = async (payload: object) => {
    try {
      return await axios.post(`/verify-2fa`, payload);
    }
    catch (error) {
      return error
    }
  }

  return {
    isAuthenticated,
    getAuthenticatedUser,
    getUser,
    getUserReq,
    getUserDetail,
    getUserDetailReq,
    enable2faReq,
    disable2faReq,
    verify2faReq
  }
});
