<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
import { useAuthStore } from '../../stores/auth'
import { useRouter } from 'vue-router'

const router = useRouter()
const { colors, setHSLAColor } = useColors()
const hoverColor = computed(() => setHSLAColor(colors.focus, { a: 0.1 }))
const { t } = useI18n()
const isShown = ref(false)

const storeAuth = useAuthStore()
const user = ref({
  name: '',
  username: '',
  email: '',
  created_at: '',
})

async function getUserReq() {
  await storeAuth.getUserReq()
  user.value = storeAuth.getUser
}

async function logout() {
  router.push('/logout')
}

onMounted( async () => {
 await getUserReq()
})
</script>

<template>
  <div class="profile-dropdown-wrapper">
    <VaDropdown v-model="isShown" :offset="[9, 0]" class="profile-dropdown" stick-to-edges>
      <template #anchor>
        <VaButton preset="secondary" color="textPrimary">
          <span class="flex items-center profile-dropdown__anchor min-w-max bg-gray-400 text-white px-3 py-2">
            <span class="material-symbols-outlined mr-2">account_circle</span>
            {{ user.name }}</span>
        </VaButton>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full shadow-md"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList>
          <RouterLink to="/account">
            <VaListItem class="menu-item px-4 text-base cursor-pointer h-8">
              <VaIcon name="mso-account_circle" class="pr-1" color="secondary" /> {{ t('user.profile') }}
            </VaListItem>
          </RouterLink>
          <RouterLink to="/settings">
            <VaListItem class="menu-item px-4 text-base cursor-pointer h-8">
              <VaIcon name="mso-settings" class="pr-1" color="secondary" /> {{ t('user.settings') }}
            </VaListItem>
          </RouterLink>
          <VaListSeparator class="mx-3 my-2" />
          <RouterLink to="" @click="logout()">
            <VaListItem class="menu-item px-4 text-base cursor-pointer h-8">
              <VaIcon name="mso-logout" class="pr-1" color="secondary" /> {{ t('user.logout') }}
            </VaListItem>
          </RouterLink>
        </VaList>
        
      </VaDropdownContent>
    </VaDropdown>
  </div>
</template>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>
