<template>
  <div class="flex items-center">
    <div class="flex justify-center w-[180px] md:w-[220px]">
      <div class="logo">
        <router-link to="/"><img src="../assets/images/safepays-logo.png" alt="Safepays" /></router-link>
      </div>
    </div>
    <button @click="sideMenuSizeChange()"
      class="hidden md:flex items-center justify-center bg-backgroundPrimary p-3 text-color w-[45px] h-[58px]">
      <span v-if="!sideMenuSizeButton" class="material-symbols-outlined text-[28px]">menu</span>
      <span v-else class="material-symbols-outlined text-[28px]">close</span>
    </button>
  </div>
  <div class="userMenu flex items-center justify-center pl-4">
    <select v-model="storeGlobal.languageCode" @change="setLanguage(storeGlobal.languageCode)"
      class="capitalize bg-backgroundSecondary border border-borderColor text-color text-sm outline-none focus:outline-none w-[65px] mr-2 px-2 md:p-2.5 h-10 hidden sm:block cursor-pointer">
      <option v-for="(item, index) in languages" :key="index" :value="item.value">{{ item.name }}</option>
    </select>
    <ProfileDropdown class="profileDropdown app-navbar-actions__item app-navbar-actions__item--profile" />
    <MobileMenu />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import ProfileDropdown from './navbar/ProfileDropdown.vue'
import MobileMenu from './navbar/MobileMenu.vue'
import { useGlobalStore } from '../stores/global'
import { useI18n } from 'vue-i18n'

const storeGlobal = useGlobalStore()
const { t } = useI18n()
const { locale } = useI18n()
const sideMenuSizeButton = ref(false)

if(localStorage.getItem('locale')) {
  storeGlobal.languageCode = localStorage.getItem('locale')
}

const languages = [
  {
    name: t('language.en'),
    value: 'en',
  },
  {
    name: t('language.tr'),
    value: 'tr',
  },
]

function setLanguage(item: any) {
  localStorage.setItem('locale', item)
  locale.value = item
}

const emit = defineEmits(['emitMenuSize'])

function sideMenuSizeChange() {
  sideMenuSizeButton.value = !sideMenuSizeButton.value
  emit('emitMenuSize');
}

onMounted(() => {
  if (localStorage.getItem('locale')) {
    locale.value = localStorage.getItem('locale')
  }
})

</script>

<style scoped>

</style>